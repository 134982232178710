body {
  height: 100vh;
  width: 100vw;
}

#canvasContainer {
  top: 0;
  position: fixed;
  height: 100vh;
  width: 100vw;
}

@keyframes flash {
  0%  {
    background-color: white;
    color: black;
    border: 2px solid black;
  }
  50% {
    background-color: black;
    color: white;
    border: 2px solid white;
  }
}

.initial {
    padding: 0rem 1rem 0.2rem 1rem;
    border: 2px solid black;
    background-color: white;
    color: black;
    font-size: 20pt;
    font-family: 'Grenze', serif;
    animation-name: flash;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    white-space: nowrap;
}
