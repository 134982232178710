
.mint {
    font-family: 'Grenze', serif;
    position: relative;
    top: 30vh;
    background: white;
    width: 50%;
    margin: auto;
    padding: 20px;
    z-index: 100;
    font-size: large;
}

.mint input {
    margin-left: 2rem;
    background: black;
    color: white;
    border: none;
    font-size: large;
    max-width: 80%;
}

.mint img {
    width:  100%;
}

input:-internal-autofill-selected {
    background: black;
    color: white;
    border: none;
}

.mint button {
    padding: 0.2rem 1rem;
    border: none;
    background: black;
    color: white;
    font-size: large;
}

.address {
    text-overflow: ellipsis;
    overflow: hidden;
    width: 80%;
    display: inline-block;
}